import React from 'react'
import WalletLayout from 'src/templates/wallet'
import PageHead from 'src/components/head/PageHead'

// Styles & Images
import 'static/mobile/scss/styles.scss'

const images = {
  headerImage: {
    aspectRatio: 1.8,
    path: '/mobile/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1536,
      },
      {
        fileName: 'header-bg-lsize',
        width: 3200,
      },
    ],
  },

  headerPreview: [
    {
      aspectRatio: 0.4618535586,
      alt: 'Exodus Wallet Portfolio',
      path: '/mobile/img/',
      fileType: 'png',
      webp: true,
      files: [
        {
          fileName: 'header-img1-lsize',
          width: 1680,
        },
      ],
    },
    {
      aspectRatio: 0.4618535586,
      alt: 'Exodus Wallet Portfolio',
      path: '/mobile/img/',
      fileType: 'png',
      webp: true,
      files: [
        {
          fileName: 'header-img2-lsize',
          width: 1680,
        },
      ],
    },
    {
      aspectRatio: 0.4618535586,
      alt: 'Exodus Wallet Portfolio',
      path: '/mobile/img/',
      fileType: 'png',
      webp: true,
      files: [
        {
          fileName: 'header-img3-lsize',
          width: 1680,
        },
      ],
    },
  ],

  exchangePreview: [
    {
      aspectRatio: 1,
      alt: 'Swap digital assets anytime and anywhere',
      path: '/mobile/img/',
      fileType: 'png',
      files: [
        {
          fileName: 'exchange-img1-lsize',
          width: 2182,
        },
      ],
    },
    {
      aspectRatio: 0.4618535586,
      alt: 'Swap digital assets anytime and anywhere',
      path: '/mobile/img/',
      fileType: 'png',
      files: [
        {
          fileName: 'header-img1-lsize',
          width: 902,
        },
      ],
    },
    {
      aspectRatio: 1,
      alt: 'Swap digital assets anytime and anywhere',
      path: '/mobile/img/',
      fileType: 'png',
      files: [
        {
          fileName: 'exchange-img3-lsize',
          width: 2182,
        },
      ],
    },
  ],

  sendReceivePreview: [
    {
      aspectRatio: 0.4617834395,
      alt: 'Send and Receive Digital Assets',
      path: '/mobile/img/',
      fileType: 'png',
      webp: true,
      files: [
        {
          fileName: 'send-receive1-lsize',
          width: 870,
        },
      ],
    },
    {
      aspectRatio: 0.4617834395,
      alt: 'Send and Receive Digital Assets',
      path: '/mobile/img/',
      fileType: 'png',
      webp: true,
      files: [
        {
          fileName: 'send-receive2-lsize',
          width: 870,
        },
      ],
    },
  ],
}

const MobilePage = ({ location }) => (
  <WalletLayout
    location={location}
    page="mobile"
    color={2}
    headerImage={images.headerImage}
    headerPreview={images.headerPreview}
    exchangePreview={images.exchangePreview}
    sendReceivePreview={images.sendReceivePreview}
  />
)

export default MobilePage

// <head> component:
export function Head() {
  return <PageHead page="mobile" />
}
